// comparison field title regex
export const compDTRegex = / - dashboard time/gm;
export const compValueRegex = / - \d*[s|m|h|d|w|M|Q|y]/gm;

let _context: CanvasRenderingContext2D;
const cache = new Map<string, TextMetrics>();
const cacheLimit = 500;
let ctxFontStyle = "";

// use regex values to get timeshift from field title
export function extractTimeshiftFromTitle(title?: string) {
  if (!title) return null;

  const timeshiftArr = title.match(compDTRegex) || title.match(compValueRegex);

  if (!timeshiftArr) return null;

  const timeshift = timeshiftArr[timeshiftArr.length - 1].replace(/ - /, "");
  return timeshift || null;
}

// format timeshift string to be displayed
export function formatComparisonTimeshift(timeshift: string) {
  if (!timeshift) return "";
  return timeshift === "dashboard time" ? "same time range" : `previous ${timeshift}`;
}

// Retrieve 2d canvas context
function getCanvasContext() {
  if (!_context) {
    // implemented by Grafana; expected non-null assertion behavior
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    _context = document.createElement("canvas").getContext("2d")!;
  }
  return _context;
}

// Determine dimensions of a text area in the panel
function measureText(text: string, fontSize: number, fontWeight = 400): TextMetrics {
  const fontStyle = `${fontWeight} ${fontSize}px 'Inter'`;
  const cacheKey = text + fontStyle;
  const fromCache = cache.get(cacheKey);

  if (fromCache) {
    return fromCache;
  }

  const context = getCanvasContext();

  if (ctxFontStyle !== fontStyle) {
    context.font = ctxFontStyle = fontStyle;
  }

  const metrics = context.measureText(text);

  if (cache.size === cacheLimit) {
    cache.clear();
  }

  cache.set(cacheKey, metrics);

  return metrics;
}

// Determine size of font for display values based on text, panel size, and line size
export function calculateFontSize(
  text: string,
  width: number,
  height: number,
  lineHeight: number,
  maxSize?: number,
  fontWeight?: number
) {
  // calculate width in 14px
  const textSize = measureText(text, 14, fontWeight);
  // how much bigger than 14px can we make it while staying within our width constraints
  const fontSizeBasedOnWidth = Math.max((width / (textSize.width + 2)) * 7, 8);
  const fontSizeBasedOnHeight = Math.max(height / lineHeight, 8);

  // final fontSize
  const optimalSize = Math.min(fontSizeBasedOnHeight, fontSizeBasedOnWidth);
  return Math.min(optimalSize, maxSize ?? optimalSize);
}
