import { PanelPlugin } from "@grafana/data";
import {
  BigValueColorMode,
  BigValueJustifyMode,
  BigValueTextMode,
  commonOptionsBuilder,
  sharedSingleStatMigrationHandler,
} from "@grafana/ui";

import { Options, addStandardDataReduceOptions, defaultOptions, statPanelChangedHandler } from "./panelConfig";

import { ComparisonStatPanel } from "./ComparisonStatPanel";
import { ComparisonStatSuggestionsSupplier } from "./Suggestions";

// Responsible for grafana right-hand panel options, including standard view options and the added stat styles options
// Also contains control of suggestions, panel change handeling, and migraton handling
// plugin constant is required to be exported for use in rendering panel:
// eslint-disable-next-line import/no-unused-modules
export const plugin = new PanelPlugin<Options>(ComparisonStatPanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    const mainCategory = ["Stat styles"];

    addStandardDataReduceOptions(builder);
    commonOptionsBuilder.addTextSizeOptions(builder);

    builder.addSelect({
      path: "textMode",
      name: "Text mode",
      description: "Control if name and value is displayed or just name",
      category: mainCategory,
      settings: {
        options: [
          { value: BigValueTextMode.Auto, label: "Auto" },
          { value: BigValueTextMode.Value, label: "Value" },
          { value: BigValueTextMode.ValueAndName, label: "Value and name" },
          { value: BigValueTextMode.Name, label: "Name" },
          { value: BigValueTextMode.None, label: "None" },
        ],
      },
      defaultValue: defaultOptions.textMode,
    });

    builder
      .addSelect({
        path: "colorMode",
        name: "Value Color mode",
        defaultValue: BigValueColorMode.Value,
        category: mainCategory,
        settings: {
          options: [
            { value: BigValueColorMode.None, label: "None" },
            { value: BigValueColorMode.Value, label: "Value" },
            { value: BigValueColorMode.Background, label: "Background Gradient" },
          ],
        },
      })
      .addRadio({
        path: "justifyMode",
        name: "Text alignment",
        defaultValue: defaultOptions.justifyMode,
        category: mainCategory,
        settings: {
          options: [
            { value: BigValueJustifyMode.Auto, label: "Auto" },
            { value: BigValueJustifyMode.Center, label: "Center" },
          ],
        },
      })
      .addRadio({
        path: "comparisonColorMode",
        name: "Comparison Values Color Mode",
        description: "An increase is:",
        defaultValue: defaultOptions.comparisonColorMode,
        category: mainCategory,
        settings: {
          options: [
            { value: true, label: "Good" },
            { value: false, label: "Bad" },
          ],
        },
      });
  })
  .setNoPadding()
  .setPanelChangeHandler(statPanelChangedHandler)
  .setSuggestionsSupplier(new ComparisonStatSuggestionsSupplier())
  .setMigrationHandler(sharedSingleStatMigrationHandler);
