import { CSSProperties } from "react";
import tinycolor from "tinycolor2";

import { DisplayValue, VizOrientation, formattedValueToString } from "@grafana/data";
import { BigValueColorMode, BigValueJustifyMode, BigValueTextMode } from "@grafana/schema";

import { IconSize, getTextColorForAlphaBackground } from "@grafana/ui";
import { BigValueComparisonProps } from "./BigValueComparison";
import { calculateFontSize } from "./ComparisonStatUtils";

const LINE_HEIGHT = 0.9;
const MAX_TITLE_SIZE = 30;
const VALUE_FONT_WEIGHT = 500;

// Class responsible for producing and passing all details about formatting of display values in the panel
// Standalone values in this class were calculated for use by core grafana. Original file with these numbers can be found here:
// https://github.com/grafana/grafana/blob/main/packages/grafana-ui/src/components/BigValue/BigValueLayout.tsx
abstract class BigValueComparisonLayout {
  titleFontSize: number;
  valueFontSize: number;
  chartHeight: number;
  chartWidth: number;
  valueColor: string;
  panelPadding: number;
  justifyCenter: boolean;
  titleToAlignTo?: string;
  valueToAlignTo: string;
  maxTextWidth: number;
  maxTextHeight: number;
  textValues: BigValueComparisonTextValues;

  constructor(private props: BigValueComparisonProps) {
    const { width, height, value, text } = props;

    this.valueColor = value.color ?? "gray";
    this.panelPadding = height > 150 ? 12 : 8;
    this.textValues = getTextValues(props);
    this.justifyCenter = shouldJustifyCenter(props.justifyMode, this.textValues.title);
    this.valueToAlignTo = this.textValues.valueToAlignTo;
    this.titleToAlignTo = this.textValues.titleToAlignTo;
    this.titleFontSize = 0;
    this.valueFontSize = 0;
    this.chartHeight = 0;
    this.chartWidth = 0;
    this.maxTextWidth = width - this.panelPadding * 2;
    this.maxTextHeight = height - this.panelPadding * 2;

    // Explicit font sizing
    if (text) {
      if (text.titleSize) {
        this.titleFontSize = text.titleSize;
        this.titleToAlignTo = undefined;
      }
      if (text.valueSize) {
        this.valueFontSize = text.valueSize;
        this.valueToAlignTo = "";
      }
    }
  }

  // return font size, line height, background color, and padding for a value's title
  getTitleStyles(): CSSProperties {
    const styles: CSSProperties = {
      fontSize: `${this.titleFontSize}px`,
      lineHeight: LINE_HEIGHT,
    };

    if (this.props.parentOrientation === VizOrientation.Horizontal && this.justifyCenter) {
      styles.paddingRight = "0.75ch";
    }

    if (this.props.colorMode === BigValueColorMode.Background) {
      styles.color = getTextColorForAlphaBackground(this.valueColor, this.props.theme.isDark);
    }

    return styles;
  }

  // return font and line sizing, position, text alignment, and color for a value
  getValueStyles(): CSSProperties {
    const styles: CSSProperties = {
      fontSize: this.valueFontSize,
      fontWeight: VALUE_FONT_WEIGHT,
      lineHeight: LINE_HEIGHT,
      position: "relative",
      zIndex: 1,
    };

    if (this.justifyCenter) {
      styles.textAlign = "center";
    }

    switch (this.props.colorMode) {
      case BigValueColorMode.Value:
        styles.color = this.valueColor;
        break;
      case BigValueColorMode.Background:
        styles.color = getTextColorForAlphaBackground(this.valueColor, this.props.theme.isDark);
        break;
      case BigValueColorMode.None:
        styles.color = this.props.theme.colors.text.primary;
        break;
    }

    return styles;
  }

  // return font and line sizing, position, text alignment, and color for a comparison value
  getComparisonValueStyles(): CSSProperties {
    const styles: CSSProperties = {
      fontSize: Math.max(this.valueFontSize / 5, 12),
      fontWeight: VALUE_FONT_WEIGHT,
      lineHeight: LINE_HEIGHT,
      position: "relative",
      zIndex: 1,
    };

    if (this.justifyCenter) {
      styles.textAlign = "center";
    }

    styles.color = this.props.theme.colors.text.primary;

    /* determine color of comparison values and arrow, based on if increase is "good" or "bad".
    "#73BF69" is "good" (matching a normal value's default green) and "#F2495C" is "bad" (matching a normal value's default red)*/
    const goodGreen = "#73BF69";
    const badRed = "#F2495C";

    if (this.textValues.arrow == "up") {
      if (this.props.comparisonColorMode) {
        styles.color = goodGreen;
      } else {
        styles.color = badRed;
      }
    } else if (this.textValues.arrow == "down") {
      if (this.props.comparisonColorMode) {
        styles.color = badRed;
      } else {
        styles.color = goodGreen;
      }
    }

    return styles;
  }

  getComparisonTextStyles(): CSSProperties {
    return {
      fontSize: Math.max(this.valueFontSize / 5, 12),
      fontWeight: VALUE_FONT_WEIGHT,
      lineHeight: LINE_HEIGHT,
      position: "relative",
      zIndex: 1,
      color: this.props.theme.colors.text.primary,
    };
  }

  getArrowSize(): IconSize {
    const fontSize = this.valueFontSize / 5;
    let arrowSize: IconSize = "xxxl";

    if (fontSize < 15) {
      arrowSize = "xl";
    } else if (fontSize < 25) {
      arrowSize = "xxl";
    }

    return arrowSize;
  }

  // set values for centered and non-centered panel pieces
  getValueAndTitleContainerStyles() {
    const styles: CSSProperties = {
      display: "flex",
    };

    if (this.justifyCenter) {
      styles.alignItems = "center";
      styles.justifyContent = "center";
      styles.flexGrow = 1;
    }

    return styles;
  }

  // retrieve panel sizing and position values
  getPanelStyles(): CSSProperties {
    const { width, height, theme, colorMode } = this.props;

    const panelStyles: CSSProperties = {
      width: `${width}px`,
      height: `${height}px`,
      padding: `${this.panelPadding}px`,
      borderRadius: "3px",
      position: "relative",
      display: "flex",
    };

    // adjust color of values and background based on dark or light grafana theme
    const themeFactor = theme.isDark ? 1 : -0.7;

    switch (colorMode) {
      case BigValueColorMode.Background:
        const bgColor2 = tinycolor(this.valueColor)
          .darken(15 * themeFactor)
          .spin(8)
          .toRgbString();
        const bgColor3 = tinycolor(this.valueColor)
          .darken(5 * themeFactor)
          .spin(-8)
          .toRgbString();
        panelStyles.background = `linear-gradient(120deg, ${bgColor2}, ${bgColor3})`;
        break;
      case BigValueColorMode.Value:
        panelStyles.background = `transparent`;
        break;
    }

    if (this.justifyCenter) {
      panelStyles.alignItems = "center";
      panelStyles.flexDirection = "row";
    }

    return panelStyles;
  }

  // set position of cahrt on panel piece
  getChartStyles(): CSSProperties {
    return {
      position: "absolute",
      right: 0,
      bottom: 0,
    };
  }
}

// panel layout used if width is > 2.5x height
class WideLayout extends BigValueComparisonLayout {
  constructor(props: BigValueComparisonProps) {
    super(props);

    const valueWidthPercent = this.titleToAlignTo?.length ? 0.3 : 1.0;

    if (this.valueToAlignTo.length) {
      // initial value size
      this.valueFontSize = calculateFontSize(
        this.valueToAlignTo,
        this.maxTextWidth * valueWidthPercent,
        this.maxTextHeight,
        LINE_HEIGHT,
        undefined,
        VALUE_FONT_WEIGHT
      );
    }

    if (this.titleToAlignTo?.length) {
      // How big can we make the title and still have it fit
      this.titleFontSize = calculateFontSize(
        this.titleToAlignTo,
        this.maxTextWidth * 0.6,
        this.maxTextHeight,
        LINE_HEIGHT,
        MAX_TITLE_SIZE
      );

      // make sure it's a bit smaller than valueFontSize
      this.titleFontSize = Math.min(this.valueFontSize * 0.7, this.titleFontSize);
    }
  }

  override getValueAndTitleContainerStyles() {
    const styles = super.getValueAndTitleContainerStyles();
    styles.flexDirection = "row";
    styles.alignItems = "center";
    styles.flexGrow = 1;

    if (!this.justifyCenter) {
      styles.justifyContent = "space-between";
    }

    return styles;
  }

  override getPanelStyles() {
    const panelStyles = super.getPanelStyles();
    panelStyles.alignItems = "center";
    return panelStyles;
  }
}

// panel layout used if width is < 2.5x height
class StackedLayout extends BigValueComparisonLayout {
  constructor(props: BigValueComparisonProps) {
    super(props);

    const { height } = props;
    const titleHeightPercent = 0.15;
    let titleHeight = 0;

    if (this.titleToAlignTo?.length) {
      this.titleFontSize = calculateFontSize(
        this.titleToAlignTo,
        this.maxTextWidth,
        height * titleHeightPercent,
        LINE_HEIGHT,
        MAX_TITLE_SIZE
      );

      titleHeight = this.titleFontSize * LINE_HEIGHT;
    }

    if (this.valueToAlignTo.length) {
      this.valueFontSize = calculateFontSize(
        this.valueToAlignTo,
        this.maxTextWidth,
        this.maxTextHeight - titleHeight,
        LINE_HEIGHT,
        undefined,
        VALUE_FONT_WEIGHT
      );
    }

    if (this.titleToAlignTo?.length) {
      // make title fontsize it's a bit smaller than valueFontSize
      this.titleFontSize = Math.min(this.valueFontSize * 0.7, this.titleFontSize);
    }
  }

  override getValueAndTitleContainerStyles() {
    const styles = super.getValueAndTitleContainerStyles();
    styles.flexDirection = "column";
    styles.flexGrow = 1;
    return styles;
  }

  override getPanelStyles() {
    const styles = super.getPanelStyles();
    styles.alignItems = "center";
    return styles;
  }
}

// use wide or stacked layout to build the layout object
export function buildLayout(props: BigValueComparisonProps): BigValueComparisonLayout {
  const { width, height } = props;
  const useWideLayout = width / height > 2.5; // ratio of 2.5x width to height determines wide or stacked layout

  if (useWideLayout) {
    return new WideLayout(props);
  } else {
    return new StackedLayout(props);
  }
}

// center values if justify center mode is true
function shouldJustifyCenter(justifyMode?: BigValueJustifyMode, title?: string) {
  if (justifyMode === BigValueJustifyMode.Center) {
    return true;
  }

  return (title ?? "").length === 0;
}

// All numbers to display for comparison values
interface BigValueComparisonTextValues extends DisplayValue {
  valueToAlignTo: string;
  titleToAlignTo?: string;
  changeValue?: number;
  percentChangeValue?: number;
  arrow?: string;
  tooltip?: string;
}

// return actual values to display on panel based on BigValueTextMode setting chosen
function getTextValues(props: BigValueComparisonProps): BigValueComparisonTextValues {
  const { value, alignmentFactors, count, valueChange, percentageChange, arrow } = props;
  let { textMode } = props;

  const titleToAlignTo = alignmentFactors ? alignmentFactors.title : value.title;
  const valueToAlignTo = formattedValueToString(alignmentFactors ? alignmentFactors : value);

  // In the auto case we only show title if this big value is part of more panes (count > 1)
  if (textMode === BigValueTextMode.Auto && (count ?? 1) === 1) {
    textMode = BigValueTextMode.Value;
  }

  switch (textMode) {
    case BigValueTextMode.Name:
      return {
        ...value,
        title: undefined,
        prefix: undefined,
        suffix: undefined,
        text: value.title || "",
        titleToAlignTo: undefined,
        valueToAlignTo: titleToAlignTo ?? "",
        tooltip: formattedValueToString(value),
      };
    case BigValueTextMode.Value:
      return {
        ...value,
        title: undefined,
        titleToAlignTo: undefined,
        valueToAlignTo,
        tooltip: value.title,
        changeValue: valueChange,
        percentChangeValue: percentageChange,
        arrow: arrow,
      };
    case BigValueTextMode.None:
      return {
        numeric: value.numeric,
        color: value.color,
        title: undefined,
        text: "",
        titleToAlignTo: undefined,
        valueToAlignTo: "1",
        tooltip: `Name: ${value.title}\nValue: ${formattedValueToString(value)}`,
      };
    case BigValueTextMode.ValueAndName:
    default:
      return {
        ...value,
        titleToAlignTo,
        valueToAlignTo,
        changeValue: valueChange,
        percentChangeValue: percentageChange,
        arrow: arrow,
      };
  }
}
