import {
  FieldOverrideContext,
  PanelModel,
  PanelOptionsEditorBuilder,
  ReducerID,
  escapeStringForRegex,
  getFieldDisplayName,
  standardEditorsRegistry,
} from "@grafana/data";
import * as common from "@grafana/schema";
import { SingleStatBaseOptions } from "@grafana/schema";
import { BigValueColorMode, BigValueTextMode, sharedSingleStatPanelChangedHandler } from "@grafana/ui";
import { compDTRegex, compValueRegex } from "./ComparisonStatUtils";

// Right-side options panel "Stat styles" section
export interface Options extends common.SingleStatBaseOptions {
  colorMode: common.BigValueColorMode;
  justifyMode: common.BigValueJustifyMode;
  textMode: common.BigValueTextMode;
  comparisonColorMode: boolean;
}

// create constant for default options settings
export const defaultOptions: Partial<Options> = {
  colorMode: common.BigValueColorMode.Value,
  justifyMode: common.BigValueJustifyMode.Auto,
  textMode: common.BigValueTextMode.Auto,
  comparisonColorMode: true,
};

// This is called when the panel changes from another panel
export const statPanelChangedHandler = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  panel: PanelModel<Partial<Options>> | any,
  prevPluginId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prevOptions: any
) => {
  // This handles most config changes
  const options = sharedSingleStatPanelChangedHandler(panel, prevPluginId, prevOptions) as Options;

  // Changing from angular singlestat
  if (prevOptions.angular && (prevPluginId === "singlestat" || prevPluginId === "grafana-singlestat-panel")) {
    const oldOptions = prevOptions.angular;

    if (oldOptions.colorBackground) {
      options.colorMode = BigValueColorMode.Background;
    } else if (oldOptions.colorValue) {
      options.colorMode = BigValueColorMode.Value;
    } else {
      options.colorMode = BigValueColorMode.None;
    }

    if (oldOptions.valueName === "name") {
      options.textMode = BigValueTextMode.Name;
    }
  }

  return options;
};

// includes standard "Value options" option section in right-side panel options
export function addStandardDataReduceOptions<T extends SingleStatBaseOptions>(
  builder: PanelOptionsEditorBuilder<T>,
  includeFieldMatcher = true
) {
  const valueOptionsCategory = ["Value options"];

  builder.addRadio({
    path: "reduceOptions.values",
    name: "Show",
    description: "Calculate a single value per column or series or show each row",
    settings: {
      options: [
        { value: false, label: "Calculate" },
        { value: true, label: "All values" },
      ],
    },
    category: valueOptionsCategory,
    defaultValue: false,
  });

  builder.addNumberInput({
    path: "reduceOptions.limit",
    name: "Limit",
    description: "Max number of rows to display",
    category: valueOptionsCategory,
    settings: {
      placeholder: "25",
      integer: true,
      min: 1,
      max: 5000,
    },
    showIf: (options) => options.reduceOptions.values === true,
  });

  builder.addCustomEditor({
    id: "reduceOptions.calcs",
    path: "reduceOptions.calcs",
    name: "Calculation",
    description: "Choose a reducer function / calculation",
    category: valueOptionsCategory,
    editor: standardEditorsRegistry.get("stats-picker").editor,
    defaultValue: [ReducerID.lastNotNull],
    // Hides it when all values mode is on
    showIf: (currentConfig) => currentConfig.reduceOptions.values === false,
  });

  if (includeFieldMatcher) {
    builder.addSelect({
      path: "reduceOptions.fields",
      name: "Fields",
      description: "Select the fields that should be included in the panel",
      category: valueOptionsCategory,
      settings: {
        allowCustomValue: true,
        options: [],
        getOptions: async (context: FieldOverrideContext) => {
          const options = [{ value: "", label: "Numeric Fields" }];
          if (context && context.data) {
            for (const frame of context.data) {
              for (const field of frame.fields) {
                const name = getFieldDisplayName(field, frame, context.data);
                const value = `/^${escapeStringForRegex(name)}$/`;
                if (name !== "time" && !compDTRegex.test(name) && !compValueRegex.test(name)) {
                  options.push({ value, label: name });
                }
              }
            }
          }
          return Promise.resolve(options);
        },
      },
      defaultValue: "",
    });
  }
}
