import { css, cx } from "@emotion/css";
import {
  DecimalCount,
  DisplayValue,
  DisplayValueAlignmentFactors,
  GrafanaTheme2,
  IconName,
  VizOrientation,
} from "@grafana/data";
import {
  BigValueColorMode,
  BigValueJustifyMode,
  BigValueTextMode,
  FormattedValueDisplay,
  Icon,
  VizTextDisplayOptions,
} from "@grafana/ui";
import React from "react";
import { buildLayout } from "./BigValueComparisonLayout";
import { Arrow } from "./types";

const clearButtonStyles = (theme: GrafanaTheme2) => {
  return css({
    background: "transparent",
    color: theme.colors.text.primary,
    border: "none",
    padding: 0,
  });
};

export interface BigValueComparisonProps {
  /** Height of the component */
  height: number;
  /** Width of the component */
  width: number;
  /** Value displayed as Big Value */
  value: DisplayValue;
  /** onClick handler for the value */
  onClick?: React.MouseEventHandler<HTMLElement>;
  /** Custom styling */
  className?: string;
  /** Color mode for coloring the value or the background */
  colorMode: BigValueColorMode;
  /** Auto justify value and text or center it */
  justifyMode?: BigValueJustifyMode;
  /** Factors that should influence the positioning of the text  */
  alignmentFactors?: DisplayValueAlignmentFactors;
  /** Explicit font size control */
  text?: VizTextDisplayOptions;
  /** Specify which text should be visible in the BigValue */
  textMode?: BigValueTextMode;
  /** If true disables the tooltip */
  hasLinks?: boolean;
  /** The orientation of the parent container */
  parentOrientation?: VizOrientation;
  /**
   * If part of a series of stat panes, this is the total number.
   * Used by BigValueTextMode.Auto text mode.
   */
  count?: number;
  /** Grafana Theme values */
  theme: GrafanaTheme2;
  /** Value determines if an increase is good (true) or bad (false) */
  comparisonColorMode?: boolean;
  /** How many decimals are displayed in the percentage and value change value */
  decimals?: DecimalCount;
  /** Value of the change between the two comparison time ranges */
  valueChange?: number;
  /** Value of the percentage change between the two comparison time ranges */
  percentageChange?: number;
  /** Determines direction of the comparison change arrow */
  arrow?: Arrow;
  /** Comparison time range to be displayed */
  timeshift?: string;
}

// Constructs display components based on the given props and results from buildLayout()
export const BigValueComparison = (props: BigValueComparisonProps) => {
  const { onClick, className, hasLinks, theme, decimals } = props;
  const layout = buildLayout(props);
  const panelStyles = layout.getPanelStyles();
  const valueAndTitleContainerStyles = layout.getValueAndTitleContainerStyles();
  const valueStyles = layout.getValueStyles();
  const comparisonValueStyles = layout.getComparisonValueStyles();
  const comparisonTextStyles = layout.getComparisonTextStyles();
  const arrowSize = layout.getArrowSize();
  const titleStyles = layout.getTitleStyles();
  const textValues = layout.textValues;

  // set arrow direction
  let arrowIcon: IconName = "minus";
  switch (textValues.arrow) {
    case "up":
      arrowIcon = "arrow-up";
      break;
    case "down":
      arrowIcon = "arrow-down";
      break;
    case "even":
      arrowIcon = "minus";
      break;
  }

  // When there is an outer data link this tooltip will override the outer native tooltip
  const tooltip = hasLinks ? undefined : textValues.tooltip;

  // Values rendering to be used w/ or w/o a link button
  const renderingValues = (
    <div style={valueAndTitleContainerStyles}>
      {textValues.title && <div style={titleStyles}>{textValues.title}</div>}
      <FormattedValueDisplay value={textValues} style={valueStyles} />
      <span style={comparisonValueStyles}>
        <Icon name={arrowIcon} size={arrowSize} />
        {(textValues.percentChangeValue ?? 0) > 0 ? "+" : ""}
        {textValues.percentChangeValue?.toFixed(decimals ?? 0)}% ({(textValues.changeValue ?? 0) > 0 ? "+" : ""}
        {textValues.changeValue?.toFixed(decimals ?? 0)})
      </span>
      <span style={comparisonTextStyles}>
        {!!props.timeshift ? `Compared to ${props.timeshift}` : "No comparison data"}
      </span>
    </div>
  );

  // return panel with a button if there is an onClick received, otherwise do not
  if (!onClick) {
    return (
      <div className={className} style={panelStyles} title={tooltip}>
        {renderingValues}
      </div>
    );
  }
  return (
    <button
      type="button"
      className={cx(clearButtonStyles(theme), className)}
      style={panelStyles}
      onClick={onClick}
      title={tooltip}
    >
      {renderingValues}
    </button>
  );
};
