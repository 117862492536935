import React, { PureComponent } from "react";

import {
  DisplayValueAlignmentFactors,
  FieldDisplay,
  getDisplayValueAlignmentFactors,
  getFieldDisplayValues,
  PanelProps,
} from "@grafana/data";
import { config } from "@grafana/runtime";
import { BigValueTextMode } from "@grafana/schema";
import { DataLinksContextMenu, DataLinksContextMenuApi, VizRepeater, VizRepeaterRenderValueProps } from "@grafana/ui";
import { BigValueComparison } from "./BigValueComparison";
import { extractTimeshiftFromTitle, formatComparisonTimeshift } from "./ComparisonStatUtils";
import { Options } from "./panelConfig";
import { Arrow, ComparisonValue } from "./types";

/* Class responsible for preparing Big Value Comparison Properties based on returned results from query.
  Extracts field display values, comparison values, side-panel options modes, etc.*/
export class ComparisonStatPanel extends PureComponent<PanelProps<Options>> {
  // track all values that should be displayed for comparison time ranges. key = query name w/o comparison time range
  comparisonValues: Record<string, ComparisonValue> = {};

  renderComponent = (
    valueProps: VizRepeaterRenderValueProps<FieldDisplay, DisplayValueAlignmentFactors>,
    menuProps: DataLinksContextMenuApi
  ): JSX.Element => {
    const { options } = this.props;
    const { value, alignmentFactors, width, height, count, orientation } = valueProps;
    const { openMenu, targetClassName } = menuProps;
    // use the same amount of decimals for the comparison value as the normal value
    const decimals = this.props.fieldConfig.defaults.decimals ?? 0;
    // get display number for the current value
    const currValue = value?.display.numeric;
    // retrieve the matching comparison value for the current normal value
    const currCompValue = this.comparisonValues[value.field.custom?.["id"].toString() ?? value.display.title];

    // set props to correct numbers
    const compNum = currCompValue?.compValue.display.numeric ?? currValue;
    const valueChange = currValue - compNum;
    const percentChange = (valueChange / compNum) * 100;

    // change arrow direction based on value change being +/-
    let changeDirection: Arrow = "even";
    if (valueChange > 0) {
      changeDirection = "up";
    } else if (valueChange < 0) {
      changeDirection = "down";
    }

    return (
      <BigValueComparison
        value={value.display}
        count={count}
        colorMode={options.colorMode}
        justifyMode={options.justifyMode}
        textMode={this.getTextMode()}
        alignmentFactors={alignmentFactors}
        parentOrientation={orientation}
        text={options.text}
        width={width}
        height={height}
        theme={config.theme2}
        onClick={openMenu}
        className={targetClassName}
        comparisonColorMode={options.comparisonColorMode}
        decimals={decimals}
        valueChange={isNaN(valueChange) ? 0 : valueChange}
        percentageChange={isNaN(percentChange) ? 0 : percentChange}
        arrow={changeDirection}
        timeshift={currCompValue?.timeshift ?? ""}
      />
    );
  };

  getTextMode() {
    const { options, fieldConfig, title } = this.props;

    // If we have manually set displayName or panel title switch text mode to value and name
    if (options.textMode === BigValueTextMode.Auto && (fieldConfig.defaults.displayName || !title)) {
      return BigValueTextMode.ValueAndName;
    }

    return options.textMode;
  }

  // Determine if links need to be passed to the renderComponent function
  renderValue = (valueProps: VizRepeaterRenderValueProps<FieldDisplay, DisplayValueAlignmentFactors>): JSX.Element => {
    const { value } = valueProps;
    const { getLinks, hasLinks } = value;

    if (hasLinks && getLinks) {
      return (
        <DataLinksContextMenu links={getLinks}>
          {(api) => {
            return this.renderComponent(valueProps, api);
          }}
        </DataLinksContextMenu>
      );
    }

    return this.renderComponent(valueProps, {});
  };

  // Generate array of field displays values and add timeshift fields to comparison values record
  getValues = (): FieldDisplay[] => {
    const { data, options, replaceVariables, fieldConfig, timeZone } = this.props;

    const unsortedFieldDisplays = getFieldDisplayValues({
      fieldConfig,
      reduceOptions: options.reduceOptions,
      replaceVariables,
      theme: config.theme2,
      data: data.series,
      timeZone,
    });

    // array to track all values that should be displayed as big values
    const currentValues: FieldDisplay[] = [];

    for (const fieldDisplay of unsortedFieldDisplays) {
      const title = fieldDisplay.display.title;
      /* if the current field is a comparison value, add it to the comparisonValues class variable.
      use the id of the corresponding value (and normal value) as the key, and set the timeshift to be displayed*/
      if (!!fieldDisplay.field.custom?.["timeshift"]) {
        const timeshift = formatComparisonTimeshift(fieldDisplay.field.custom?.["timeshift"]);

        this.comparisonValues[fieldDisplay.field.custom?.["id"].toString()] = {
          compValue: fieldDisplay,
          timeshift,
        };
      } else {
        const timeshift = extractTimeshiftFromTitle(title);
        // if the current field results from an expression, use regex to check the timeshift
        if (timeshift && title) {
          const key = title.replace(" - " + timeshift, "") || title;
          this.comparisonValues[key as string] = {
            compValue: fieldDisplay,
            timeshift: formatComparisonTimeshift(timeshift),
          };
        } else {
          // the current field is a normal value, return it with the others
          currentValues.push(fieldDisplay);
        }
      }
    }

    return currentValues;
  };

  override render() {
    const { height, options, width, data, renderCounter } = this.props;

    return (
      <VizRepeater
        getValues={this.getValues}
        getAlignmentFactors={getDisplayValueAlignmentFactors}
        renderValue={this.renderValue}
        width={width}
        height={height}
        source={data}
        itemSpacing={3}
        renderCounter={renderCounter}
        autoGrid={true}
        orientation={options.orientation}
      />
    );
  }
}
